@font-face {
  font-family: "UBU reg";
  src: url("./fonts/Ubuntu-Regular.ttf");
}

@font-face {
  font-family: "UBU lght";
  src: url("./fonts/Ubuntu-Light.ttf");
}

@font-face {
  font-family: "UBU bld";
  src: url("./fonts/Ubuntu-Bold.ttf");
}

$ubu-light: "UBU lght";
$ubu-regular: "UBU reg";
$ubu-bold: "UBU bld";

$brand-purple: #573499;
$brand-orange: #ff9323;
$soft-gray-2: #afafaf;
$soft-gray:#ffffffc3;
$hard-gray: #5d5d5d;

body {
  margin: 0;
  padding: 0;
  background-image: url('./img/bcklines.jpg');
  background-repeat: repeat;
  background-size:contain;
}

header {
  background-image: url("./img/carrusel1.jpg");
  background-size:     cover;
  background-repeat:   no-repeat;
  background-position: center center;  
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

nav {
  margin: 1rem;
  font-family: $ubu-regular;
} 

section {
  display: flex;
  margin: 0 auto;
  background-size:     cover;
  background-repeat:   no-repeat;
  background-position: center center;  
  width: 100%;
  max-width: 800px;
  padding: 0;
}

li {
  list-style: none;
  margin: 1rem;
}

.copy-right{
  color: $brand-purple;
  text-decoration: none;
  text-align: center;
} 

.header-main {
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100%;
  max-width: 800px;
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  z-index: 10;
}

.logo {
  margin: 2rem;
  width: 200px;
}

.msj-box {
  position: absolute;
  top: 200px;
  left: 60px;
  margin: 0 auto;
}

.msj-box__inner {
  width: 200px;
}

.msj-box__inner h1 {
  font-family: $ubu-regular;
  color: $brand-purple;
}

.msj-box__inner__h2 {
  font-family: $ubu-regular;
  color: $brand-orange;
}

.circle-button_unselected {
  border: 1px solid;
  padding: 1rem 2rem;
  border-radius: 30px;
  text-decoration: none;
  font-family: $ubu-regular;
  color: $soft-gray;
  background-color: $brand-purple;
  display: flex;
  width: fit-content;
  margin: 1rem;
}

.circle-button {
  border: 1px solid;
  padding: 1rem 2rem;
  border-radius: 30px;
  text-decoration: none;
  font-family: $ubu-regular;
  color: $brand-purple;
  background-color: $soft-gray;
  display: flex;
  width: fit-content;
  margin: 1rem;
}

.circle-button__org {
  background-color: $brand-orange;
  border-color:  $brand-orange;
  color: white;
}

.sec-desc {
  width: 40%;
  background-color: $brand-purple;
}

.sec-desc__img {
  width: 60%;
  background-image: url(./img/sec1.jpg);
  background-position: left;
  background-size: cover;
  opacity: .8;
}

.sec-desc__box {
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
} 

.sec-desc__box2 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding: 2rem;
} 

.sec-desc h1 {
  color: white;
  font-family: $ubu-regular;
  font-size:3rem;
  padding: 0 1rem;
}

.sec-desc p {
  color: white;
  font-family: $ubu-light;
  font-size:2rem;
  padding: 0 1rem;
}

.sec-desc__titu {
  font-family: "UBU lght" !important;
}

.sec-desc__A {
  margin-top: 2rem;
  width: 50%;
  font-family: $ubu-regular;
}

.sec-desc__A h1{
  font-family: $ubu-bold;
  color: white;
  font-size: 4rem;
  margin: 0;
  padding: 0;
}

.sec-desc__A h2{
  font-size: 2rem;
  margin: 0;
  padding: 0;
}

.sec-desc__A p{
  color: $hard-gray;
  font-size: 1.5rem;
}

.sec-desc__white {
  color: white;
}

.sec-desc__B {
  margin: 2rem;
  text-align: center;
}

.sec-desc__B img{
  width: 100%;
  max-width: 140px;
}

.sec-desc__C {
  margin-top: 2rem;
  width: 30%;
  font-family: $ubu-regular;
  padding: 2rem;
}

.sec-desc__C img{
  width: 100%;
}


.sec-desc__C p{
  color: $hard-gray;
  font-size: 1.4rem;
}


.sec-footer {
  height: 100px;
  display: flex;
  flex-direction: column;
  margin-top: 20%;
  margin-left: 3rem;
}

.sec-footer img {
  width: 40px;
  height: 40px;
}

.sec-footer p {
  font-family: $ubu-light;
  padding-bottom: 2rem;
  color: $hard-gray;
  text-decoration: none;
}

.desc1 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 800px;
  padding: 2rem 0;
}

.desc2 {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-image: linear-gradient(45deg, $soft-gray-2, $soft-gray);
}

.desc3 {
  background-image: url("./img/sec3.jpg");
  height: 400px;
  opacity: .8;
}

.socials {
  margin: 1rem;
}


.socials a {
  text-decoration: none;
}

.newsletter {
  width: 100%;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
}

.newsletter-header {
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #bbb;
}

.newsletter-header img {
  height: 58px;
  opacity: 0;
}

.newsletter-header h1 {
  font-family: $ubu-regular;
  color: $brand-purple;
  font-size: xx-large;
}

.newsletter-header__titu {
  font-family: $ubu-bold;
  color: $brand-orange;
}

.newsletter-header2 {
  display: flex;
  flex-direction: row;
  padding: 2rem;
}

.newsletter-header2 h1{
  width: 50%;
  font-family: $ubu-regular;
  color: $brand-purple;
  font-size: 3rem;
}

.newsletter-header2 img{
  width: 50%;
}

.newsletter-header2__titu {
  font-family: $ubu-bold;
}

.newsletter-header2__txt {
  padding: 2rem;
  font-family: $ubu-regular;
  line-height: 2rem;
}

.products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background-size:     cover;
  background-repeat:   no-repeat;
  background-position: center center;  
  background-image: linear-gradient(45deg, $soft-gray-2, $soft-gray);
}

.produ {
  background-size:     cover;
  background-repeat:   no-repeat;
  background-position: center center;  
  width: 40%;
  display: flex;
  min-width: 300px;
  padding: 2rem;
}

.produ img {
  height: fit-content;
}

.product-header {
  background-image: url(./img/prodbck.png);
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2rem auto;
  margin-top: 2rem;
}

.product-header h1 {
  font-family: $ubu-bold;
  color: $brand-purple;
  font-size: 4rem;
  margin: 1rem auto;
}

.product-header h2 {
  font-family: $ubu-bold;
  color: $hard-gray;
  font-size: 2rem;
  margin: 0 auto;
}

.product-header__imgrack {
  margin: 1rem;
  padding-top: 2rem;
} 

.product-header__imgrack img{
  max-width: 120px;
  height: fit-content;
}

.produ__txtbox_g h1{
  font-family: $ubu-bold;
  color: #75af02;
}

.produ__txtbox_b h1{
  font-family: $ubu-bold;
  color: #396bc3;
}

.produ__txtbox_p h1{
  font-family: $ubu-bold;
  color: #fe4541;
}

.produ__txtbox_v h1{
  font-family: $ubu-bold;
  color: #a76fc8;
}

.produ__txtbox_o h1{
  font-family: $ubu-bold;
  color: #4fb173;
}

.produ__txtbox_e h1{
  font-family: $ubu-bold;
  color: #00b0a8;
}

.produ__txtbox_g2 h1{
  font-family: $ubu-bold;
  color: #168132;
}

.produ p{
  font-family: $ubu-light;
  color: $hard-gray;
}

.wearemito-box2 {
  opacity: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.wearemito {
  width: 80%;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.wearemito-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.wearemito-header img {
  height: 58px;
  opacity: 0;
}

.wearemito-header h1 {
  font-family: $ubu-light;
  color: $brand-purple;
  font-size: 2.2rem;
  line-height: 4rem;
}

.wearemito-header p {
  font-family: $ubu-regular;
  color: $hard-gray;
  font-size: 1.4rem;
  line-height: 1.5;
  text-align: justify;
}

.wearemito-header__title h1 {
  font-family: $ubu-bold;
  color: $brand-purple;
  font-size: 3rem;
  font-weight: bold;
  margin: 1rem auto;
}

.wearemito-header__titu {
  color: $brand-orange;
  font-family: $ubu-bold;
}

.wearemito-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 3rem;
}

.wearemito-box__column {
  width: 50%;
  padding-bottom: 2rem;
}

.wearemito-box__column img {
  width: 100%;
}

.wearemito-box__column h1 {
  font-family: $ubu-bold;
  color: $brand-purple;
  padding: 0 1rem ;
  font-size: 2rem;
  margin: 0;
  margin-left: 1rem;
}

.wearemito-box__column p {
  font-family: $ubu_light;
  color: $hard-gray;
  padding:0 1rem;
  line-height: 1.8;
  font-size: 1rem;
  margin-left: 1rem;
  text-align: justify;
}

.wearemito-upnav {
  display: flex;
  justify-content: space-between;
  border-bottom: solid $hard-gray 1px;
}

.wearemito-upnav h1{
  font-family: $ubu-bold;
  font-size: 4rem;
  color: $brand-purple;
}

.wearemito-upnav h1 span{
  color: $brand-orange;
}

.wearemito-upnav__close_button {
  margin-top: 3rem;
  min-width:max-content;
}

.wearemito-upnav__link {
  background-color: $brand-purple;
  padding: 1rem;
  border-radius: 40px;
  margin: 1rem;
  color: white;
  text-decoration: none;
  font-family: $ubu-regular;
  min-width: 200px;
}

.newsletter-header2 {
  display: flex;
  flex-direction: row;
  padding: 2rem;
}

.newsletter-header2 h1{
  width: 50%;
  font-family: $ubu-regular;
  color: $brand-purple;
  font-size: 3rem;
}

.newsletter-header2 img{
  width: 50%;
}

.newsletter-header2__titu {
  font-family: $ubu-bold;
}

.newsletter-header2__txt {
  padding: 2rem;
  font-family: $ubu-regular;
  line-height: 2rem;
}

.contact-card {
  margin: 1rem auto;
  max-width: 800px;
}

.pro-selector__menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.pro-selector__button {
  width: 47%;
  background-color: $soft-gray-2;
  color: $brand-purple;
  text-decoration: none;
  font-family: $ubu-bold;
  font-size: 2rem;
  text-align: center;
  margin: .5rem;
  min-height: 200px;
  justify-content: center;
  align-items: center;
  align-self:auto;
}

.pro-selector__button_txt {
  margin: 1rem;
  padding: 2rem;
}

.newsTi {
  margin-top: 3rem;
}

.newsTi h1 {
  font-size: 3rem;
}

.newsTitu-span {
  font-family: $ubu-regular;
}

.firma-news {
  font-style: italic;
}

.carru {
  width: 100%;
  max-width: 1336px;
  margin: 0 auto;
  height: 600px;
}

.icoimg {
  opacity: 1 !important;
  height: max-content !important;
}

.wearemito-header__titu-box {
  display: flex;
  width: 100%;
  justify-content:space-between;
}

.wearemito-header__titu-box_area {
  width: 100%;
  border-bottom: solid 1px $hard-gray;
}

.wearemito-header__titu-ico_area {
  margin: auto 0;
}

.circ-carru-btn {
  background-color: $brand-orange;
  margin: .5rem;
  width: 16px;
  height: 16px;
  border: none;
  border-radius: 10px;
}


.circ-carru-btn2 {
  background-color: $brand-purple !important;
  margin: .5rem;
  width: 16px;
  height: 16px;
  border: none;
  border-radius: 10px;
}

.circ-carru-nav {
  display: none;
  justify-content: center;
  margin: 0 auto;
}

.lowerNav {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin: 2rem auto;

  &--caption {
    background-color: rgba(255, 255, 255, 0.696);
    font-size: small;
    padding: .4rem;
    border-radius: 10px;
    animation: simpleFadeIn 2s ease-in; 
  }

  &--logo {
    max-width: 180px;
    text-align: center;
    font-family: $ubu-regular;
    text-decoration: none;

    & img {
      max-width: 160px;
      width: 100%;
    }
  }
}

//animations
@keyframes simpleFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes simpleLeftToRight {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}


/*slider*/
.awssld__container figure, .awssld__content, .awssld__box {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.awssld__controls button, .awssld__bullets button {
  outline-color: 0;
  outline-style: none;
  outline-width: 0; }

.awssld {
  --organic-arrow-thickness: 4px;
  --organic-arrow-height: 40px;
  --slider-height-percentage: 60%;
  --loader-bar-color: #851515;
  --loader-bar-height: 6px;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-opacity: 0.5;
  --control-button-hover-opacity: 0.75;
  --control-button-background: transparent;
  --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
  --slider-transition-duration: 575ms;
  --organic-arrow-color: #6a6a6a;
  --organic-arrow-border-radius: 0;
  --control-bullet-color: #6a6a6a;
  --control-bullet-active-color: #6a6a6a;
  --content-background-color: #ffffff; }

.awssld {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%; }
  .awssld__wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%; }
  .awssld__container {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: var(--slider-height-percentage); }
    @media all and (max-width: 500px) {
      .awssld__container {
        padding-bottom: calc(var(--slider-height-percentage) * 1.25); } }
  .awssld__startUp {
    background-color: red;
    height: 100%;
    width: 100%; }
    .awssld__startUp > div {
      height: 100%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .awssld__startUp img {
      width: 35%;
      height: auto; }
  .awssld__content {
    background-color: white;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .awssld__content > img,
    .awssld__content > video {
      -o-object-fit: cover;
         object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; }
  .awssld__controls button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: var(--control-button-width);
    height: var(--control-button-height);
    position: absolute;
    z-index: 10;
    top: calc(50% - (0.5 * var(--control-button-height)));
    border: none;
    background-color: var(--control-button-background);
    color: #fff;
    cursor: pointer; }
    .awssld__controls button .awssld__controls__arrow-left,
    .awssld__controls button .awssld__controls__arrow-right {
      opacity: var(--control-button-opacity); }
    .awssld__controls button:hover .awssld__controls__arrow-left,
    .awssld__controls button:hover .awssld__controls__arrow-right {
      opacity: var(--control-button-opacity-hover); }
  .awssld__controls--active .awssld__controls__arrow-left {
    opacity: var(--control-button-opacity-hover);
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  .awssld__controls--active .awssld__controls__arrow-right {
    opacity: var(--control-button-opacity-hover);
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  .awssld__controls--hidden {
    display: none; }
  @media all and (max-width: 520px) {
    .awssld__controls {
      visibility: hidden; } }
  .awssld__bar {
    display: block;
    width: 100%;
    height: var(--loader-bar-height);
    background-color: var(--loader-bar-color);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
    -webkit-transition: -webkit-transform 3000ms var(--transition-bezier);
    transition: -webkit-transform 3000ms var(--transition-bezier);
    transition: transform 3000ms var(--transition-bezier);
    transition: transform 3000ms var(--transition-bezier), -webkit-transform 3000ms var(--transition-bezier);
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
    .awssld__bar--active {
      -webkit-transform: translate3d(-20%, 0, 0);
              transform: translate3d(-20%, 0, 0); }
    .awssld__bar--end {
      -webkit-transition-duration: 300ms;
              transition-duration: 300ms;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }
  .awssld__next {
    right: 0; }
  .awssld__prev {
    left: 0; }
  .awssld__box {
    z-index: 1;
    visibility: hidden; }
  .awssld--animated {
    will-change: transform;
    visibility: visible; }
  .awssld--animated-mobile {
    will-change: transform;
    -webkit-transition: -webkit-transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
    transition: -webkit-transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
    transition: transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
    transition: transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1), -webkit-transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1); }
  .awssld--active {
    visibility: visible;
    z-index: 2;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  .awssld--moveRight, .awssld--moveLeft {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  .awssld--moveRight {
    -webkit-animation: slideFromRight var(--slider-transition-duration) both var(--transition-bezier);
            animation: slideFromRight var(--slider-transition-duration) both var(--transition-bezier); }
  .awssld--moveLeft {
    -webkit-animation: slideFromLeft var(--slider-transition-duration) both var(--transition-bezier);
            animation: slideFromLeft var(--slider-transition-duration) both var(--transition-bezier); }
  .awssld--exit {
    z-index: 0; }
    .awssld--exit.awssld--moveLeft {
      -webkit-animation: slideToLeft var(--slider-transition-duration) both var(--transition-bezier);
              animation: slideToLeft var(--slider-transition-duration) both var(--transition-bezier); }
    .awssld--exit.awssld--moveRight {
      -webkit-animation: slideToRight var(--slider-transition-duration) both var(--transition-bezier);
              animation: slideToRight var(--slider-transition-duration) both var(--transition-bezier); }
  .awssld--first .awssld__prev {
    visibility: hidden; }
  .awssld--last .awssld__next {
    visibility: hidden; }
  .awssld--fill-parent {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0; }
    .awssld--fill-parent .awssld__container {
      height: 100%;
      padding: 0; }
  .awssld__bullets {
    position: absolute;
    bottom: -40px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .awssld__bullets button {
      padding: 0;
      display: block;
      width: 16px;
      height: 16px;
      margin: 5px;
      border-radius: 50%;
      background: var(--control-bullet-color);
      text-indent: -9999px;
      overflow: hidden;
      cursor: pointer;
      border: none;
      -webkit-transition: background-color 0.175s ease-out, -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45);
      transition: background-color 0.175s ease-out, -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45);
      transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out;
      transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out, -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45); }
      .awssld__bullets button:hover {
        -webkit-transform: scale(1.2);
                transform: scale(1.2); }
    .awssld__bullets .awssld__bullets--loading {
      -webkit-transform: scale(1.2);
              transform: scale(1.2); }
    .awssld__bullets .awssld__bullets--active {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
      background: var(--control-bullet-active-color); }
      .awssld__bullets .awssld__bullets--active:hover {
        -webkit-transform: scale(1.5);
                transform: scale(1.5); }
  .awssld__controls__arrow-left, .awssld__controls__arrow-right {
    width: 100%;
    height: var(--organic-arrow-height);
    position: relative;
    display: block;
    -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out 0.125s;
    transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out 0.125s;
    transition: transform 0.2s ease-out 0.125s, opacity 0.2s ease-out;
    transition: transform 0.2s ease-out 0.125s, opacity 0.2s ease-out, -webkit-transform 0.2s ease-out 0.125s; }
    .awssld__controls__arrow-left:before, .awssld__controls__arrow-left:after, .awssld__controls__arrow-right:before, .awssld__controls__arrow-right:after {
      content: ' ';
      position: absolute;
      right: calc( 50% - ( 0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness)) ) / 2);
      height: 100%;
      border-radius: var(--organic-arrow-border-radius);
      width: var(--organic-arrow-thickness);
      background-color: var(--organic-arrow-color);
      -webkit-transition: background-color 0.15s ease-out, -webkit-transform 0.15s ease-out;
      transition: background-color 0.15s ease-out, -webkit-transform 0.15s ease-out;
      transition: transform 0.15s ease-out, background-color 0.15s ease-out;
      transition: transform 0.15s ease-out, background-color 0.15s ease-out, -webkit-transform 0.15s ease-out; }
    .awssld__controls__arrow-left:before, .awssld__controls__arrow-right:before {
      -webkit-transform-origin: 100% 100% 0;
              transform-origin: 100% 100% 0;
      top: -50%;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
    .awssld__controls__arrow-left:after, .awssld__controls__arrow-right:after {
      -webkit-transform-origin: 100% 0% 0;
              transform-origin: 100% 0% 0;
      top: 50%;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
  .awssld__controls__arrow-right--active {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
    .awssld__controls__arrow-right--active:after {
      -webkit-transform: rotate(90deg) translate3d(50%, 0, 0) !important;
              transform: rotate(90deg) translate3d(50%, 0, 0) !important; }
    .awssld__controls__arrow-right--active:before {
      -webkit-transform: rotate(-90deg) translate3d(50%, 0, 0) !important;
              transform: rotate(-90deg) translate3d(50%, 0, 0) !important; }
  .awssld__controls__arrow-left:before, .awssld__controls__arrow-left:after {
    right: auto;
    left: calc( 50% - ( 0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness)) ) / 2); }
  .awssld__controls__arrow-left:before {
    -webkit-transform-origin: 0 100% 0;
            transform-origin: 0 100% 0;
    top: -50%;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .awssld__controls__arrow-left:after {
    -webkit-transform-origin: 0 0 0;
            transform-origin: 0 0 0;
    top: 50%;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .awssld__controls__arrow-left--active {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
    .awssld__controls__arrow-left--active:after {
      -webkit-transform: rotate(-90deg) translate3d(-50%, 0, 0) !important;
              transform: rotate(-90deg) translate3d(-50%, 0, 0) !important; }
    .awssld__controls__arrow-left--active:before {
      -webkit-transform: rotate(90deg) translate3d(-50%, 0, 0) !important;
              transform: rotate(90deg) translate3d(-50%, 0, 0) !important; }
  .awssld__controls button:hover .awssld__controls__arrow-left:before {
    opacity: 1;
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg); }
  .awssld__controls button:hover .awssld__controls__arrow-left:after {
    opacity: 1;
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg); }
  .awssld__controls button:hover .awssld__controls__arrow-right:before {
    opacity: 1;
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg); }
  .awssld__controls button:hover .awssld__controls__arrow-right:after {
    opacity: 1;
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg); }
  .awssld__timer {
    --timer-delay: 2000ms;
    --timer-release: 200ms;
    --timer-height: 4px;
    --timer-background-color: rgba(0, 0, 0, 0.15);
    width: 100%;
    height: var(--timer-height);
    background-color: var(--timer-background-color);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
    .awssld__timer--animated {
      will-change: transform;
      -webkit-transition: -webkit-transform var(--timer-delay) linear;
      transition: -webkit-transform var(--timer-delay) linear;
      transition: transform var(--timer-delay) linear;
      transition: transform var(--timer-delay) linear, -webkit-transform var(--timer-delay) linear; }
    .awssld__timer--run {
      -webkit-transform: translateX(0);
              transform: translateX(0); }
    .awssld__timer--fast {
      -webkit-transition: -webkit-transform calc(var(--timer-release) / 2) linear;
      transition: -webkit-transform calc(var(--timer-release) / 2) linear;
      transition: transform calc(var(--timer-release) / 2) linear;
      transition: transform calc(var(--timer-release) / 2) linear, -webkit-transform calc(var(--timer-release) / 2) linear;
      -webkit-transform: translateX(-0.00001px);
              transform: translateX(-0.00001px); }
    .awssld__timer--end {
      -webkit-transform: translateX(-0.0001px);
              transform: translateX(-0.0001px);
      -webkit-transition: -webkit-transform var(--timer-release) linear;
      transition: -webkit-transform var(--timer-release) linear;
      transition: transform var(--timer-release) linear;
      transition: transform var(--timer-release) linear, -webkit-transform var(--timer-release) linear; }
    .awssld__timer--hidden {
      display: none; }

@-webkit-keyframes slideFromLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes slideFromLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes slideFromRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes slideFromRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes slideToLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); } }

@keyframes slideToLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); } }

@-webkit-keyframes slideToRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); } }

@keyframes slideToRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); } }





@media (max-width: 720px) {


  .contact-card {
    flex-direction: column;
  }



.wearemito-box__column {
  width: 100%;
}

  .navbar {
      flex-direction: row;
      display: flex;
      flex-direction:column;
      margin: 1rem;
      padding: 0;
      width: 40%;
  }

  .header-main {
    flex-direction: row;
    flex-flow: row;
    display: flex;
    justify-content: space-between;
  }

  .lowerNav {
    display: flex;
    flex-flow: row;
    flex-direction: column;
    width: 100px;
    margin: 1rem;
    padding: 0;
  }


  .lowerNav--logo img{
    max-width: 100px;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
  }

  .logo {
      margin: 0;
      width: 100px;
  }

  .logo img {
      width: 100px;
  } 

  nav {
      margin: 0;
      width: 140%;
      position: relative;
      animation: simpleLeftToRight 1s ease-in;
  }

  .sec-desc {
      width: 100%;
  }

  .sec-desc__img {
      width: 100%;
      height: 300px;
      background-size: cover;
      background-position: 10% 40%;
  }

  .desc1 {
      flex-direction: column;
      flex-wrap: nowrap;
  }

  .desc2 {
      flex-direction: column;
  }

  .sec-desc__A {
    overflow: hidden;
    width: 80%;
    padding: 0;
  }

  .sec-desc__B {
    overflow: hidden;
    width: 80%;
    padding: 0;
  }

  .sec-desc__C {
    overflow: hidden;
    width: 80%;
    padding: 0;
  }

  .wearemito-upnav {
    flex-direction: column-reverse;
  }

  .pro-selector__menu {
    flex-direction: column;
  }

  .pro-selector__button {
    width: 100%;
  }

  .msj-box {
    position: absolute;
    top: 40px;
    left: auto;
    right: 60px;
    margin: 0 auto;
  }

  .circle-button {
    border: 1px solid;
    padding: .5rem 1rem;
    border-radius: 30px;
    text-decoration: none;
    font-family: $ubu-regular;
    color: $brand-purple;
    background-color: $soft-gray;
    display: flex;
    width: fit-content;
    margin: .5rem;
    font-size: .8rem;
  }
}

/* @media (max-width: 800px){
  .wearemito-box__column {
    width: 100%;
  }
} */